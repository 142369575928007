/* eslint-disable react/jsx-filename-extension,camelcase */
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BannerSection from '../components/banner';
import brandBannerAdYogi from '../images/co-branded-partners/co-branded-banner.svg';
import brandBannerWinkl from '../images/co-branded-partners/winkl-banner.svg';
import brandBannerSezzle from '../images/co-branded-partners/sezzle-banner.svg';
import brandBannerPickrr from '../images/co-branded-partners/pickrr-banner.svg';
import brandBannerZomato from '../images/co-branded-partners/zomato-banner.svg';
import adyogiLogo from '../images/co-branded-partners/adyogi-logo.png';
import winklLogo from '../images/co-branded-partners/winkl-logo.png';
import sezzleLogo from '../images/co-branded-partners/sezzle-logo.png';
import pickrrLogo from '../images/co-branded-partners/pickrr-logo.svg';
import zomatoLogo from '../images/co-branded-partners/zomato-logo.png';
import style from '../pages/styles/brands.module.scss';
import { FeaturesCard, ProcessSection } from '../components/brands';
import { Carousal } from '../components/landing';
import flexible from '../../static/brands/png/flexible.png';
import seamlessbanner from '../../static/brands/patterns/png/square.png';
import seamless from '../../static/brands/svg/seamless.svg';
import capital from '../../static/brands/svg/capital.svg';
import product from '../../static/brands/png/product.png';
import { openURL, saveURLParamToSession } from '../utils';
import apply from '../../static/brands/svg/apply.svg';
import funding from '../../static/brands/svg/funding.svg';
import assessment from '../../static/brands/svg/assessment.svg';
import repayment from '../../static/brands/svg/repayment.svg';
import HOME_SMOOR from '../images/co-branded-partners/brands/smoor.png';
import HOME_SOMETHINGS_BREWING from '../images/co-branded-partners/brands/somethings_brewing.png';
import HOME_MAMA_GOTO from '../images/co-branded-partners/brands/mama_goto.png';
import HOME_DHABA from '../images/co-branded-partners/brands/dhaba.png';
import HOME_ARTINCI from '../images/co-branded-partners/brands/artinci.png';
import HOME_BEWAKOOF from '../images/co-branded-partners/brands/bewakoof.png';
import HOME_MAMA_KETO from '../images/co-branded-partners/brands/mama_keto.png';
import HOME_THE_MAN_COMPANY from '../images/co-branded-partners/brands/the_man_company.png';
import BlogCoverImage from '../images/co-branded-partners/blog-image.png';
import '../common-styles/new-styles.scss';
import Styles from '../pages/styles/landingPage.module.scss';
import partnersStyles from '../pages/styles/partners.module.scss';

const zomato = 'zomato';

const features = [
  {
    icon: seamless,
    title: 'Seamless & Easy',
    content: 'Seamless process managed end-to-end by our in-house experts',
    banner: seamlessbanner
  },
  {
    icon: flexible,
    title: 'Flexible repayments',
    content: 'Flexible repayments as a percentage of your brand’s revenues'
  },
  {
    icon: product,
    title: 'Product Structure',
    content: 'Tailored product structure that matches your needs'
  },
  {
    icon: capital,
    title: 'Capital without Constraints',
    content: 'No equity dilution, retain complete control of your business'
  }
];

const leftProcesses = [
  {
    icon: apply,
    title: 'Apply',
    content: 'Share your business and financial data securely'
  },
  {
    icon: funding,
    title: 'Funding',
    content: 'Confirm commercials and sign documentation to access financing '
  }
];

const rightProcesses = [
  {
    icon: assessment,
    title: 'Assessment',
    content:
      'Our underwriting engine evaluates your brand and creates a term sheet'
  },
  {
    icon: repayment,
    title: 'Repayment',
    content: 'Pay via easy revenue shares, till your flat fee is paid off  '
  }
];

const processes = [
  {
    icon: apply,
    title: 'Apply',
    content: 'Share your business and financial data securely'
  },
  {
    icon: assessment,
    title: 'Assessment',
    content:
      'Our underwriting engine evaluates your brand and creates a term sheet'
  },
  {
    icon: funding,
    title: 'Funding',
    content: 'Confirm commercials and sign documentation to access financing '
  },
  {
    icon: repayment,
    title: 'Repayment',
    content: 'Pay via easy revenue shares, till your flat fee is paid off '
  }
];

const zomatoFeatures = [
  {
    icon: seamless,
    title: 'Our version of friendly funding',
    content: 'Zomato merchants enjoy discounted pricing on Klub products',
    banner: seamlessbanner
  },
  {
    icon: flexible,
    title: 'Unlock more funding as your grow',
    content: 'Instead of 90 days, Zomato merchants are eligible for second round of funding with Klub in just 60 days.'
  },
  {
    icon: product,
    title: 'At your service literally',
    content: 'Unlock funding in 48 hours with a dedicated team at Klub to service your requirements'
  },
  {
    icon: capital,
    title: 'Always eligible for more',
    content: 'Leverage our partnership with Zomato with higher capital commitments by Klub.'
  }
];

const zomatoLeftProcesses = [
  {
    icon: apply,
    title: 'Apply',
    content: 'Securely share your revenue channel & financial data in a single click'
  },
  {
    icon: funding,
    title: 'Funding',
    content: 'Check our offer, sign it and confirm the fund transfer'
  }
];

const zomatoRightProcesses = [
  {
    icon: assessment,
    title: 'Assessment',
    content:
      'Our underwriting engine gets to work & creates a termsheet curated for your brand'
  },
  {
    icon: repayment,
    title: 'Repayment',
    content: 'Splurge on your business. Pay revenue shares while you earn more & become more.'
  }
];

const zomatoProcesses = [
  {
    icon: apply,
    title: 'Apply',
    content: 'Securely share your revenue channel & financial data in a single click'
  },
  {
    icon: assessment,
    title: 'Assessment',
    content:
      'Our underwriting engine gets to work & creates a termsheet curated for your brand'
  },
  {
    icon: funding,
    title: 'Funding',
    content: 'Check our offer, sign it and confirm the fund transfer'
  },
  {
    icon: repayment,
    title: 'Repayment',
    content: 'Splurge on your business. Pay revenue shares while you earn more & become more.'
  }
];

const tempBrands = [
  { name: 'SMOOR ', image: HOME_SMOOR },
  { name: 'Somethings Brewing', image: HOME_SOMETHINGS_BREWING },
  { name: 'Mamagoto', image: HOME_MAMA_GOTO },
  { name: 'Dhaba', image: HOME_DHABA },
  { name: 'Artinci', image: HOME_ARTINCI },
  { name: 'Bewakoof', image: HOME_BEWAKOOF },
  { name: 'Mama Keto', image: HOME_MAMA_KETO },
  { name: 'The Man Company', image: HOME_THE_MAN_COMPANY }
];

const defaultSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  autoplay: true,
  slidesToScroll: 1,
  slidesToShow: 6,
  speed: 1000,
  autoplaySpeed: 2000
};

const CoBrandedPartner = (props) => {
  const { pageContext, data } = props;
  const { ghostPost } = data;

  const handleBrandNavigate = (type = 'signup', customURL = '') => {
    if (customURL) {
      window.open(customURL, '_blank');
      return;
    }

    switch (type) {
      case 'calc':
        openURL('brand_app_calc');
        break;
      case 'signup':
        openURL('brand_app_signup');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  // eslint-disable-next-line consistent-return
  const getBannerImage = (partner) => {
    switch (partner) {
      case 'adyogi':
        return brandBannerAdYogi;
      case 'winkl':
        return brandBannerWinkl;
      case 'sezzle':
        return brandBannerSezzle;
      case 'zomato':
        return brandBannerZomato;
      case 'pickrr':
        return brandBannerPickrr;
      default:
        break;
    }
  };

  // eslint-disable-next-line consistent-return
  const getPartnerLogo = (partner) => {
    switch (partner) {
      case 'adyogi':
        return adyogiLogo;
      case 'winkl':
        return winklLogo;
      case 'sezzle':
        return sezzleLogo;
      case 'zomato':
        return zomatoLogo;
      case 'pickrr':
        return pickrrLogo;
      default:
        break;
    }
  };

  const handleLearnMoreClick = () => {
    const faq = document.getElementById('learn-more-section');
    if (faq) {
      faq.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Layout>
      <SEO
        title="Get instant funding for your growing brand"
        description="Klub offers quick and easy financing for entrepreneurs with zero equity dilution and flexible repayments."
      />
      <div className="co-branded-partner-container">
        <BannerSection
          isCustom={true}
          image={getBannerImage(pageContext.slug)}
          heading={pageContext.bannerSection.headingLine1}
          subHeading={pageContext.bannerSection.headingLine2}
          headingLine3={pageContext.bannerSection.headingLine3}
          headingLine4={pageContext.bannerSection.headingLine4}
          description={pageContext.bannerSection.description}
          subDescription={pageContext.bannerSection.subDescription}
          buttons={pageContext?.bannerSection?.isGetEstimate ? [
            <button
              type="button"
              onClick={() => handleBrandNavigate('signup', pageContext.bannerSection.signupLink)}
              className="btn btnklub1"
            >
              Sign Up
            </button>,
            // <button
            //   type="button"
            //   onClick={() => handleBrandNavigate('signup')}
            //   className="btn btnklub1 d-flex d-md-none"
            // >
            //   Get Funded
            // </button>,
            <button
              type="button"
              onClick={() => handleBrandNavigate('calc')}
              className="btn btnklub2 ml3"
            >
              Get an Estimate
            </button>
          ] : [
            <button
              type="button"
              onClick={() => handleBrandNavigate('signup', pageContext?.bannerSection?.signupLink)}
              className="btn btnklub1"
            >
              {pageContext?.slug === zomato ? `Join The Klub` : `Sign up`}
            </button>
          ]}
        />

        <div className={style.brandSection1}>
          <div className="container ">
            <div className={`${style.section} partner-feature-container`}>
              <div>
                <h3 className={style.sectionHeader}>
                  {pageContext.featureSection.subTitle}
                </h3>
                <h1 className={style.content}>
                  {pageContext.featureSection.titleLine1}
                  <br className="d-none d-md-block" />
                  {pageContext.featureSection.titleLine2}
                  {' '}
                  {!pageContext.props?.hideBodyTitle && (<span>{pageContext.title}</span>)}
                </h1>
              </div>
              <div className="partner-image-container">
                <img
                  src={getPartnerLogo(pageContext.slug)}
                  alt={pageContext.title}
                />
              </div>
            </div>
          </div>
          <div className="features-card-partners-container">
            <div className={style.carousalWeba}>
              <Carousal
                margin={true}
                autoScroll={false}
                showGradient={false}
                name="features-card-partners"
              >
                {pageContext.slug === zomato ? (
                  zomatoFeatures.map((item) => (
                    <FeaturesCard
                      content={item?.content}
                      title={item?.title}
                      icon={item?.icon}
                      style={{
                        width: '275px',
                        marginRight: '25px'
                      }}
                    />
                  ))
                ) : (features.map((item) => (
                  <FeaturesCard
                    content={item.content}
                    title={item.title}
                    icon={item.icon}
                    style={{
                      width: '275px',
                      marginRight: '25px'
                    }}
                  />
                )))}
              </Carousal>
            </div>
            <div
              className={`${Styles.sectionButtons} ${Styles.mt4} ${partnersStyles.buttonCenter}`}
            >
              <button
                type="button"
                className="btn btnklub3"
                onClick={handleLearnMoreClick}
              >
                {pageContext?.slug === zomato ? `Join The Klub` : `Learn More`}
              </button>
            </div>
          </div>
        </div>
        <div className={style.brandSection2}>
          <div className="container featured-section">
            <div className={style.section}>
              <h1 className={style.content}>{pageContext?.slug === zomato ? `Countless brands already in the Klub` : `You are in good company`}</h1>
              <div className="row">
                <Slider {...defaultSettings}>
                  {tempBrands.map((card) => (
                    <div key={card.name} className="col py-3 home-rounded-card">
                      <div className="home-rounded-card-container">
                        <img
                          alt={card.name}
                          src={card.image}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className={style.section} id="learn-more-section">
              <h3 className={style.sectionHeader}>{pageContext?.slug === zomato ? `HOW DOES IT WORK?` : `HOW THE PROCESS WORKS`}</h3>
              <h1 className={style.content}>
                {pageContext?.slug === zomato ? `Power of flexible growth, personally` : `Most attractive proposal`}
                <br />
                {pageContext?.slug === zomato ? `delivered to Zomato merchants` : `delivered to you seamlessly`}
              </h1>
            </div>
            {pageContext?.slug === zomato ? (
              <ProcessSection
                name="brands"
                leftProcesses={zomatoLeftProcesses}
                rightProcesses={zomatoRightProcesses}
                processes={zomatoProcesses}
              />
            ) : (
              <ProcessSection
                name="brands"
                leftProcesses={leftProcesses}
                rightProcesses={rightProcesses}
                processes={processes}
              />
            )}
          </div>
        </div>
        <div className={`${style.brandSection2} partner-blog-main`}>
          <div className="container partner-blog-main-container">
            <div className={`${ghostPost ? '' : 'd-none'}`}>
              <p className="blog-m d-block d-md-none">Blog</p>
            </div>
            <div className={`partner-blog-card ${ghostPost ? '' : 'd-none'}`}>
              <div className="partner-blog-card-image">
                <img
                  src={ghostPost?.feature_image || BlogCoverImage}
                  alt={ghostPost?.title}
                />
              </div>
              <div className="partner-blog-card-body">
                <p className="blog d-none d-md-block">Blog</p>
                <h3 className="title">{ghostPost?.title}</h3>
                <p className="description">{ghostPost?.excerpt}</p>
                <Link to={`/blog/${ghostPost?.slug}`} className="cta-link">
                  Read more
                </Link>
              </div>
            </div>
            <div className="partner-blog-main-cta">
              <button
                type="button"
                className="btn btnklub1"
                onClick={() => handleBrandNavigate('signup', pageContext.bannerSection.signupLink)}
              >
                {pageContext?.slug === zomato ? `Join The Klub` : `Sign up`}
              </button>
              {pageContext?.bannerSection?.isGetEstimate && (
                <button
                  type="button"
                  className="btn btnklub3"
                  onClick={() => handleBrandNavigate('calc')}
                >
                  Get an estimate
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CoBrandedPartner;

export const partnersQuery = graphql`
  query($slug: String!) {
    ghostPost(tags: { elemMatch: { slug: { eq: $slug } } }) {
      id
      title
      slug
      excerpt
      feature_image
    }
  }
`;
