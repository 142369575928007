/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import arrowLeft from '../../../../static/home/svg/Arrowleft.svg';
import arrowRight from '../../../../static/home/svg/Arrowright.svg';
import './styles.scss';

let currentSlide = {};
const Carousal = (props) => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [isDragStarted, toggleDragStart] = useState(false);
  const [dragPosition, setDragPosition] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [elementMarginRight, setElementMarginRight] = useState(0);
  const [elementContainerWidth, setElementContainerWidth] = useState(0);
  const [elementWidth, setElementWidth] = useState(0);
  const [childrenLength, setChildrenLength] = useState(0);
  const slidePerScroll = props.slidePerScroll || 1;

  const onBtnClick = (direction) => {
    if (direction === 'left' && selectedSlide !== 0) {
      currentSlide[props.name] += slidePerScroll;
      setSelectedSlide(selectedSlide + slidePerScroll);
    } else if (direction === 'right' && getTotalPosition() > windowWidth) {
      currentSlide[props.name] -= slidePerScroll;
      setSelectedSlide(selectedSlide - slidePerScroll);
    }
  };

  const autoScroll = () => {
    setInterval(() => {
      currentSlide[props.name] -= 1;
      setSelectedSlide(currentSlide[props.name]);
    }, 1500);
  };

  const getTotalPosition = () => {
    const totalWidth = childrenLength * (elementWidth + elementMarginRight);
    const movingPosition = (selectedSlide * elementWidth) + (selectedSlide * elementMarginRight);
    const totPosition = totalWidth + movingPosition;
    // console.log({ windowWidth, totalWidth, movingPosition, totPosition, selectedSlide });
    return totPosition;
  };

  useEffect(() => {
    // const windowWidth = document.getElementById(`carousal-wrapper-${props.name}`) && document.getElementById(`carousal-wrapper-${props.name}`).clientWidth;
    const windowWidth = document.getElementsByClassName('container') && document.getElementsByClassName('container')[0].clientWidth;
    const marginLeft = document.getElementsByClassName('container') && document.getElementsByClassName('container')[0].offsetLeft;
    const elementContainer = document.getElementsByClassName('container') && document.getElementsByClassName('container')[0];
    const elementContainerStyle = elementContainer.currentStyle || window.getComputedStyle(elementContainer);
    const elementContainerPaddingLeft = parseFloat(elementContainerStyle.paddingLeft);
    const elementContainerPaddingRight = parseFloat(elementContainerStyle.paddingRight);
    const elementContainerWidth = windowWidth - elementContainerPaddingLeft - elementContainerPaddingRight;
    const children = document.getElementById(`carousal-container-${props.name}`) && document.getElementById(`carousal-container-${props.name}`).children;
    const childrenLength = children.length;
    const element = children[0];
    const elementWidth = element.offsetWidth;
    const elementStyle = element.currentStyle || window.getComputedStyle(element);
    const elementMarginRight = parseFloat(elementStyle.marginRight);

    currentSlide = {
      ...currentSlide,
      [props.name]: 0
    };

    setMarginLeft(marginLeft);
    setElementMarginRight(elementMarginRight);
    setElementContainerWidth(elementContainerWidth);
    setWindowWidth(windowWidth);
    setElementWidth(elementWidth);
    setChildrenLength(childrenLength);
    if (props.autoScroll) autoScroll();
  }, []);

  const getLeftPosition = () => {
    const movingPosition = (selectedSlide * elementWidth) + (selectedSlide * elementMarginRight);
      // console.log({ movingPosition, selectedSlide , elementWidth, elementMarginRight, marginLeft });
    if (selectedSlide !== 0) {
      if (getTotalPosition() > windowWidth) {
        if (props.isInsideContainer) return movingPosition;
        return `calc(${movingPosition}px + ${marginLeft}px)`;
      }
      currentSlide[props.name] = 1;
      // return movingPosition + (windowWidth - getTotalPosition());
      if (props.isInsideContainer) return movingPosition ;
      return marginLeft + movingPosition;
    }
    if (props.margin) {
      return marginLeft;
    }
    return 0;
  };

  const onDragStart = (evt) => {
    const { clientX } = evt.nativeEvent;
    setDragPosition(clientX);
    toggleDragStart(true);
  };

  const onDrag = (evt) => {
    const { clientX } = evt.nativeEvent;
    if (dragPosition - clientX > 0 && getTotalPosition() > windowWidth) {
      currentSlide[props.name] -= slidePerScroll;
      setSelectedSlide(selectedSlide - slidePerScroll);
    } else if (selectedSlide !== 0) {
      currentSlide[props.name] += slidePerScroll;
      setSelectedSlide(selectedSlide + slidePerScroll);
    }
    toggleDragStart(false);
  };

  const onTouchStart = (evt) => {
    const { clientX } = evt.nativeEvent.touches[0];
    setDragPosition(clientX);
    toggleDragStart(true);
  };

  const onTouchMove = (evt) => {
    const { clientX } = evt.nativeEvent.touches[0];
    if (dragPosition - clientX > 0 && getTotalPosition() > windowWidth) {
      currentSlide[props.name] -= slidePerScroll;
      setSelectedSlide(selectedSlide - slidePerScroll);
    } else if (selectedSlide !== 0) {
      currentSlide[props.name] += slidePerScroll;
      setSelectedSlide(selectedSlide + slidePerScroll);
    }
    toggleDragStart(false);
  };

  return (
    <>
      <div className="carousalContainer" id={`carousal-wrapper-${props.name}`}>
        <div style={{ height: 'fit-content', position: 'relative' }}>
          <div
            id={`carousal-container-${props.name}`}
            className={`inputContainer ${props.isInsideContainer ? 'ml-0' : ''}`}
            style={{ left: getLeftPosition() }}
            onTouchStart={onTouchStart}
            onTouchMove={isDragStarted ? onTouchMove : undefined}
            onDragStart={onDragStart}
            onMouseMove={isDragStarted ? onDrag : undefined}
            draggable={true}
          >
            {props.children}
          </div>
          {props.showGradient && (
            <>
              {(windowWidth > 1024 || getTotalPosition() <= windowWidth) && selectedSlide !== 0 && <div className="sliderGradientLeft" style={{
                maxWidth: marginLeft
              }} />}
              {getTotalPosition() > windowWidth && <div className="sliderGradientRight" />}
            </>
          )}
        </div>
      </div>
      <div className="carousalButtonContainer">
        <div style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingRight: '12px'
        }}>
          <img
            src={arrowLeft}
            alt=""
            role="presentation"
            className={selectedSlide === 0 ? `disabledSlideBtn` : `slideBtn`}
            onClick={() => onBtnClick('left')}
          />
        </div>
        <div style={{
          width: '50%',
          'paddingLeft': '12px',
          display: 'flex'
        }}>
          <img
            src={arrowRight}
            alt=""
            role="presentation"
            className={getTotalPosition() > windowWidth ? `slideBtn` : `disabledSlideBtn`}
            onClick={() => onBtnClick('right')}
          />
        </div>
      </div>
    </>
  );
};
Carousal.propTypes = {
  name: PropTypes.string.isRequired,
  showGradient: PropTypes.bool,
  autoScroll: PropTypes.bool,
  isInsideContainer: PropTypes.bool,
};
Carousal.defaultProps = {
  showGradient: true,
  autoScroll: false,
  isInsideContainer: false
};
export default Carousal;
